<template>
  <div class="Viewed bg-white p-10">
    <ViewedList></ViewedList>
  </div>
</template>

<script>
import ViewedList from '@/views/User/Viewed/ViewedList'
/**
 * @author  XuHongli
 * @date  2022/8/31 15:44
 * @version 1.0
 * @description 历史记录
 */
export default {
  name: 'Viewed',
  components: { ViewedList },
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.Viewed{
  // background-color: rgb(240,240,240) !important;
}

</style>
