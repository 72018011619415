<template>
  <div class="ViewedList">
    <a-spin :spinning="loading" tip="Loading...">
      <!--  商品显示  imgUrl  -->
      <!--  今天    -->
      <p class="common-tip-title mb-20 mt-10">浏览历史</p>

      <div v-if="goods.length === 0">
        <span style="margin-left: 40px;">暂无数据</span>
      </div>
      <div class="goods">
        <a-card
          v-for="(item, index) in goods"
          :key="index"
          :hoverable="true"
          class="goodsCard">
          <img
            slot="cover"
            :src="item.supplyProduct ? item.supplyProduct.imgUrl: ''"
            alt="example"
            @click="$router.push({path: '/commodity/' + item.supplyProduct.id})"
          />
          <a-tooltip :title="item.supplyProduct.name" placement="top">
            <a-card-meta :title="item.supplyProduct ? item.supplyProduct.name : ''" @click="$router.push('/commodity/' + item.supplyProduct ? item.supplyProduct.id : '')">
              <template slot="description">
                <div class="card">
                  <span style="color: #d11514;font-size: 16px;font-weight: 600;">￥{{ item.supplyProduct ? item.supplyProduct.tradePrice.toFixed(2) : '' }}</span>
                  <span style="color: #000"><span style="color: rgba(9, 98, 234, 1);font-size: 14px;">{{ item.supplyProduct ? item.supplyProduct.sale : '' }}+</span> <span style="color: rgba(185, 185, 185, 1);font-size: 12px;">月销</span></span>
                </div>

                <!-- <a-icon :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item,index)" /> -->
              </template>
            </a-card-meta>
          </a-tooltip>
          <div class="btn">
            <div class="left" @click.stop="handleHeart(item,index)"><a-icon :theme="item.supplyProduct ? (item.supplyProduct .isFavorite === 1 ? 'twoTone' : 'outlined') : ''" class="heart" two-tone-color="#eb2f96" type="heart"/></div>
            <div v-if="item.supplyProduct ? item.supplyProduct.isPuint == 0 : ''" class="right" @click="handleAddGoods(item)">一键入库</div>
            <div v-else class="right">已入库</div>
          </div>
        </a-card>
      </div>

      <!--   昨天    -->

      <!--      <p style="margin-left: 20px;font-size: 12px;font-weight: 600;">昨天</p>-->
      <!--      <div v-if="formatFootprint.yesterday.length === 0">-->
      <!--        <span style="margin-left: 40px;">暂无数据</span>-->
      <!--      </div>-->
      <!--      <div class="goods">-->
      <!--        <a-card-->
      <!--          v-for="(item, index) in formatFootprint.yesterday"-->
      <!--          :key="index"-->
      <!--          :hoverable="true"-->
      <!--          class="goodsCard"-->
      <!--          @click="$router.push('/commodity/' + item.supplyProduct.id)">-->
      <!--          <img-->
      <!--            slot="cover"-->
      <!--            :src="item.supplyProduct.imgUrl"-->
      <!--            alt="example"-->
      <!--          />-->
      <!--          <a-card-meta :title="item.supplyProduct.name">-->
      <!--            <template slot="description">-->
      <!--              <div class="card">-->
      <!--                <span style="color: #d11514;font-size: 16px;font-weight: 600;">￥{{ item.supplyProduct.tradePrice }}</span>-->
      <!--                <span style="color: #000"><span style="color: rgba(9, 98, 234, 1);font-size: 14px;">{{ item.supplyProduct.sale }}+</span> <span style="color: rgba(185, 185, 185, 1);font-size: 12px;">月销</span></span>-->
      <!--              </div>-->
      <!--              <div class="btn">-->
      <!--                <div class="left" @click.stop="handleHeart(item,index)">-->
      <!--                  <a-icon :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart"/>-->
      <!--                </div>-->
      <!--                <div class="right" @click.stop="handleAddGoods(item)">{{ item.supplyProduct.isPuint === 1 ? '已入库' :'一键入库' }}</div>-->
      <!--              </div>-->
      <!--              &lt;!&ndash; <span style="color: #000">{{ '￥' + item.supplyProduct.tradePrice }}</span>-->
      <!--              <a-icon :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item,index)" /> &ndash;&gt;-->
      <!--            </template>-->
      <!--          </a-card-meta>-->
      <!--        </a-card>-->
      <!--      </div>-->
      <!--   其他   -->
      <!--      <h3 style="margin-left: 20px;margin-bottom: 0;">其他</h3>-->
      <!--      <div v-if="formatFootprint.other.length === 0">-->
      <!--        <span style="margin-left: 40px;">暂无数据</span>-->
      <!--      </div>-->
      <!--      <div class="goods">-->
      <!--        <a-card-->
      <!--          class="goodsCard"-->
      <!--          :hoverable="true"-->
      <!--          style="max-width: 240px"-->
      <!--          v-for="(item, index) in formatFootprint.other"-->
      <!--          @click="$router.push('/commodity/' + item.supplyProduct.id)"-->
      <!--          :key="index">-->
      <!--          <img-->
      <!--            slot="cover"-->
      <!--            alt="example"-->
      <!--            style="width: 238px;height: 238px"-->
      <!--            :src="item.supplyProduct.imgUrl"-->
      <!--          />-->
      <!--          <a-card-meta :title="item.supplyProduct.name">-->
      <!--            <template slot="description">-->
      <!--              <span style="color: #000">{{ '￥' + item.supplyProduct.tradePrice }}</span>-->
      <!--              <a-icon @click.stop="handleHeart(item,index)" class="heart" type="heart" :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " two-tone-color="#eb2f96" />-->
      <!--            </template>-->
      <!--          </a-card-meta>-->
      <!--        </a-card>-->
      <!--      </div>-->
      <div v-if="goods.length !== 0" class="flex-row" style="flex-direction: row-reverse;padding: 10px">
        <a-pagination
          v-model="queryParam.current"
          :page-size="queryParam.size"
          :page-size-options="pageSizeOptions"
          :total="total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </a-spin>
  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/9/5 11:17
 * @version 1.0
 * @description
 */
import { getUserViewed } from '@/api/Viewed'
import moment from 'moment'
import { favoriteProduct } from '@/api/Product'
import { mapGetters } from 'vuex'
import { hisAddSelectGoods } from '@/api/SelectGoods'

export default {
  name: 'ViewedList',
  data() {
    return {
      loading: true,
      pageSizeOptions: ['10', '20', '30'],
      goods: [],
      total: 1,
      queryParam: {
        current: 1,
        size: 30,
        // total: 0,
        descs: 'update_time',
        type: 1 // 用户足迹类型 1:足迹 2:收藏
      },
      // 格式化后的数据
      formatFootprint: {
        today: [],
        yesterday: [],
        other: [],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  created() {
    this.getShopDetail()
  },
  methods: {
    getShopDetail(){
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        this.loading = true
        getUserViewed(this.queryParam).then((res) => {
          this.loading = false
          this.goods = res.data.records
          this.total = res.data.total
          this.formatFootprint = this.formatDate(this.goods)
        }).catch(err => {
          this.loading = false
        })
      }
    },
        //  一键入库
    handleAddGoods(item, index) {
      const that = this
      const ids = [item.supplyProduct.id ? item.supplyProduct.id : null]
      this.$AddGroupModal({
        ids,
        isHistory: true,
        handleOk: (val) => {
          if (val === 'ok') {
            that.getShopDetail()
          }
        },
      })
      // this.$confirm('你确定要将这些商品一键入库吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'success'
      // }).then(() => {
      //   hisAddSelectGoods(ids).then((res) => {
      //       if (res.code === 0) {
      //         that.$message.success('入库成功')
      //         // that.onGetProductPage(that.queryData)
      //       } else if (res.code === -1) {
      //         that.$message.error(res.msg)
      //       }
      //     })
      // })
    },
    handleHeart(item, index) {
      favoriteProduct(item.supplyProduct.id).then(() => {
        item.supplyProduct.isFavorite === 1 ? item.supplyProduct.isFavorite = 0 : item.supplyProduct.isFavorite = 1
      })
    },
    formatDate(footprintList) {
      const result = {
        today: [],
        yesterday: [],
        other: [],
      }
      const today = new Date()
      const yesterday = moment(new Date()).subtract(1, 'days')

      footprintList.forEach((item) => {
        if (moment(today).isSame(item.updateTime, 'day')) {
          result.today.push(item)
        } else if (moment(yesterday).isSame(item.updateTime, 'day')) {
          result.yesterday.push(item)
        } else {
          result.other.push(item)
        }
      })

      return result
    },
    onChange(page, pageSize){
      this.queryParam.current = page
      this.queryParam.size = pageSize
      this.loading = true
      getUserViewed(this.queryParam).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
        this.formatFootprint = this.formatDate(this.goods)
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.current = 1
      this.queryParam.size = pageSize
      this.loading = true
      getUserViewed(this.queryParam).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
        this.formatFootprint = this.formatDate(this.goods)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.card{
  display: flex;
  justify-content: space-between;
}
.goods{
  padding: 0 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  .goodsCard {
    position: relative;
    margin: 10px 9px;
    width: 251px;
    height: 370px;
    box-shadow: 3.4px 3.4px 2.7px rgba(0, 0, 0, 0.016),
    8.7px 8.7px 6.9px rgba(0, 0, 0, 0.025),
    17.7px 17.7px 14.2px rgba(0, 0, 0, 0.03),
    36.5px 36.5px 29.2px rgba(0, 0, 0, 0.034);
    border-radius: 5px;
    img{
      width: 238px;
      height: 238px;
      padding: 10px 0 0 10px;
      border-radius: 10px;
      object-fit: cover;
    }

    .btn{
      position: absolute;
      padding: 0 15px;
      width: 250px;
      left: 0;
      right: 0;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      color: #fff;
      cursor: pointer;
      // border: 1px solid red;
      .left,.right{
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        background-color: #c81623;
        color: #fff;
      }
    }
  }
}
</style>
